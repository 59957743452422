<template>
  <c-box>
    <c-grid
      v-if="isLoading"
      width="100%"
      :template-columns="['repeat(3, 1fr)', 'repeat(4, 1fr)']"
      :gap="['12px', '24px']"
      :px="['8px', '0']"
      py="1rem"
      background-color="#FFF"
    >
      <c-flex
        v-for="i in ['sm', 'md'].includes(currentBreakpoint) ? 9 : 8"
        :key="i"
        width="100%"
        flex-direction="column"
        background-color="#FFF"
        :border-radius="['8px', '12px']"
        gap="8px"
        :box-shadow="['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026']"
        overflow="hidden"
      >
        <c-box
          display="block"
          width="100%"
          :height="['55px', '145px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
        <c-box
          padding-inline="8px"
          padding-bottom="8px"
        >
          <c-box
            display="block"
            width="70%"
            :height="['18px', '20px']"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              animation="fade"
              rounded
            />
          </c-box>
        </c-box>
      </c-flex>
    </c-grid>
    <c-box v-else-if="!isLoading && items.length > 0">
      <c-grid
        width="100%"
        :template-columns="['repeat(3, 1fr)', 'repeat(4, 1fr)']"
        :gap="['12px', '24px']"
        :px="['8px', '0']"
        py="1rem"
        background-color="#FFF"
      >
        <c-flex
          v-for="(gift, i) in items"
          :key="i"
          width="100%"
          height="fit-content"
          flex-direction="column"
          background-color="#FFF"
          :border-radius="['8px', '12px']"
          gap="8px"
          :box-shadow="['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026']"
          overflow="hidden"
          cursor="pointer"
          @click="onClickGift(gift)"
        >
          <c-image
            :src="gift?.image"
            width="100%"
            :height="['55px', '145px']"
            object-fit="cover"
          />
          <c-text
            :font-size="['10px', '16px']"
            font-weight="500"
            color="#111"
            padding-inline="8px"
            padding-bottom="8px"
          >
            {{ gift?.name }}
          </c-text>
        </c-flex>
      </c-grid>
      <c-flex
        justify-content="center"
        w="100%"
        margin-top="40px"
      >
        <c-flex justify-content="center">
          <c-button
            px="0"
            py="4px"
            w="32px"
            h="32px"
            background-color="transparent"
            :is-disabled="page <= 1"
            @click.prevent="onPrevious"
          >
            <c-icon
              name="chevron-left"
              size="24px"
            />
          </c-button>

          <c-button
            v-for="page_ in pages"
            :key="page_"
            v-chakra="{
              ':hover': {
                bg: 'rgba(0, 140, 129, 0.3)',
                color: 'primary.400',
              },
            }"
            px="0"
            py="4px"
            w="32px"
            h="32px"
            mx="4px"
            background-color="rgba(0, 140, 129, 0.3)"
            color="primary.400"
            :bg="
              page_ === page
                ? ['rgba(0, 140, 129, 0.3)']
                : ['transparent']
            "
            as="router-link"
            :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
          >
            {{ page_ }}
          </c-button>

          <c-button
            px="0"
            py="4px"
            w="32px"
            h="32px"
            background-color="transparent"
            :is-disabled="pages.length === page"
            @click.prevent="onNext"
          >
            <c-icon
              name="chevron-right"
              size="24px"
            />
          </c-button>
        </c-flex>
      </c-flex>
    </c-box>
    <c-flex
      v-else
      flex-direction="column"
      py="60px"
      justify-content="center"
      align-items="center"
    >
      <c-image
        :src="require('@/assets/empty-nutritionists.svg')"
        alt="empty"
        :width="['180px', '200px']"
        margin-bottom="16px"
      />
      <c-text 
        font-family="Roboto" 
        :font-size="['16px', '18px']" 
        font-weight="500"
      > 
        Hadiah tidak ditemukan
      </c-text>
    </c-flex>
  </c-box>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'

export default {
  name: 'ReferralGift',
  components: {
    VueSkeletonLoader,
  },
  mixins: [mixinsCheckBreakpoints()],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: [String, Number],
      default: 12,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },
  computed: {
    selectedCategory() {
      return this.$route.query?.category ? JSON.parse(this.$route.query?.category) : []
    },
    totalPage() {
      return Math.ceil(this.total / Number(this.perPage))
    },
    // pages() {
    //   return calculatePaginations({
    //     perPage: +this.perPage,
    //     totalData: this.total,
    //     currentPage: this.page,
    //   })
    // },
    pages() {
      let total = this.total ?? 1
      let perPage = this.perPage ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
  },
  methods: {
    onPrevious() {
      if (this.page > 1) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: this.page - 1 },
        })
      }
    },
    onNext() {
      if (this.page < this.totalPage) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: this.page + 1 },
        })
      }
    },
    onClickGift(item) {
      this.$router.push({
        name: 'client.profile.referral.gift.detail',
        params: {
          id: item?.id,  
        },
      })
    },
  },
}
</script>

<style scoped></style>
