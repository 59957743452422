var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "max-width": "1270px",
      "width": "100%",
      "margin-inline": "auto",
      "margin-top": ['0px', '30px'],
      "padding": ['16px 0 0 0', '30px 20px'],
      "border-radius": ['0px', '16px'],
      "box-shadow": ['none', '2px 2px 10px 0px #00000026'],
      "background-color": ['#F2F2F2', '#FFF']
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['none', 'flex'],
      "width": "100%",
      "px": "30px",
      "py": "5px",
      "mb": "20px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400",
      "gap": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.profile.referral'
        });
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-chevron-left.svg'),
      "height": "20",
      "width": "20"
    }
  }), _vm._v(" Kembali ")], 1)], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1120px",
      "margin-inline": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": ['column', 'row'],
      "align-item": "center",
      "justify-content": "space-between",
      "gap": ['12px', '16px'],
      "padding-inline": ['16px', '0px'],
      "margin-bottom": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['100%', '230px'],
      "order": ['2', '1']
    }
  }, [_c('CardReferralPoint', {
    attrs: {
      "point": _vm.poin
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "order": ['1', '2']
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "8px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": ['15px', '25px'],
      "height": ['20px', '24px'],
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": ['40px', '62px'],
      "font-size": ['14px', '18px'],
      "placeholder": "Search",
      "border-radius": ['30px', '20px'],
      "padding-left": ['45px', '60px'],
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "input": _vm.handleSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('c-menu', {
    attrs: {
      "auto-select": false,
      "close-on-select": true
    },
    on: {
      "blur": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "background-color": ['white', '#F2F2F2'],
      "color": "gray.900",
      "p": ['0', '8px 20px'],
      "border-radius": ['8px', '1rem'],
      "d": "flex",
      "align-items": "center",
      "gap": "12px",
      "h": ['40px', '62px'],
      "w": ['40px', 'fit-content']
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "color": "#333333",
      "font-size": "16px"
    }
  }, [_vm._v(" Filter ")]), _c('c-box', {
    attrs: {
      "display": ['none', 'block'],
      "width": ['20px', '30px'],
      "height": ['20px', '30px'],
      "gap": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#333333"
    }
  })], 1), _c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "width": ['20px', '30px'],
      "height": ['20px', '30px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1), _vm.selectedCategory ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "-2px",
      "right": "-2px",
      "width": ['8px', '10px'],
      "height": ['8px', '10px'],
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "100%",
      "font-size": "10px",
      "background-color": "danger.400",
      "color": "#FFF"
    }
  }) : _vm._e()], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": ['150px', '200px'],
      "max-width": ['200px', '250px'],
      "flex-direction": "column",
      "py": "12px",
      "px": "8px",
      "placement": "left-start",
      "border-radius": "1rem"
    }
  }, [_vm._l(_vm.categories, function (item, i) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.categories.length > 0,
        expression: "categories.length > 0"
      }],
      key: i
    }, [_c('c-menu-item', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: 'primary.50'
          }
        },
        expression: "{\n                    ':hover': {\n                      backgroundColor: 'primary.50'\n                    }\n                  }"
      }],
      attrs: {
        "gap": "12px",
        "px": ['8px', '16px'],
        "py": ['4px', '8px'],
        "background-color": _vm.selectedCategory && _vm.selectedCategory == (item === null || item === void 0 ? void 0 : item.id) ? 'primary.50' : '#FFF',
        "color": _vm.selectedCategory && _vm.selectedCategory == (item === null || item === void 0 ? void 0 : item.id) ? 'primary.400' : '#888',
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeCategory(item.id);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '18px'],
        "font-weight": ['500']
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.categoryName) + " ")])], 1), _vm.categories.length !== i + 1 ? _c('c-menu-divider', {
      attrs: {
        "border-color": "#FFF",
        "py": "4px",
        "m": "0"
      }
    }) : _vm._e()], 1);
  }), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.categories.length == 0,
      expression: "categories.length == 0"
    }],
    attrs: {
      "px": "1rem",
      "font-size": "16px",
      "font-weight": "500",
      "color": "danger.400"
    }
  }, [_vm._v(" Belum ada filter ")])], 2)], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "background-color": "#FFF",
      "padding-inline": ['8px', '0px']
    }
  }, [_c('c-tabs', {
    staticClass: "custom-tab",
    attrs: {
      "is-fitted": "",
      "is-manual": "",
      "index": _vm.activeTabIndex,
      "default-index": _vm.activeTabIndex
    },
    on: {
      "change": _vm.onChangeTab
    }
  }, [_c('c-tab-list', _vm._l(['Hadiah', 'Riwayat Poin'], function (label, index) {
    return _c('c-tab', {
      key: index
    }, [_vm._v(" " + _vm._s(label) + " ")]);
  }), 1)], 1), _vm.activeTabIndex == 0 ? _c('Gift', {
    attrs: {
      "items": _vm.gifts,
      "categories": _vm.categories,
      "page": _vm.page,
      "total": _vm.total,
      "per-page": _vm.perPage,
      "is-loading": _vm.isLoading
    }
  }) : _vm.activeTabIndex == 1 ? _c('PoinHistory', {
    attrs: {
      "items": _vm.gifts,
      "categories": _vm.categories,
      "page": _vm.page,
      "total": _vm.total,
      "per-page": _vm.perPage,
      "is-loading": _vm.isLoading
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }