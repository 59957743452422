var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm.isLoading ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": ['12px', '24px'],
      "px": ['8px', '0'],
      "py": "1rem",
      "background-color": "#FFF"
    }
  }, _vm._l(['sm', 'md'].includes(_vm.currentBreakpoint) ? 4 : 6, function (i) {
    return _c('c-flex', {
      key: i,
      attrs: {
        "width": "100%",
        "flex-direction": "column",
        "gap": ['4px', '8px']
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": "100px",
        "height": ['14px', '18px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-flex', {
      attrs: {
        "width": "100%",
        "flex-direction": "row",
        "align-items": "center",
        "background-color": "#FFF",
        "border-radius": ['8px', '8px'],
        "gap": ['8px', '16px'],
        "padding": ['8px', '12px'],
        "box-shadow": ['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026'],
        "overflow": "hidden"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": ['80px', '120px'],
        "height": ['60px', '90px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "flex-direction": "column",
        "justify-content": "space-between",
        "height": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": "80%",
        "height": ['18px', '20px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-flex', {
      attrs: {
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": "80px",
        "height": ['16px', '18px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-flex', {
      attrs: {
        "flex-shrink": "0",
        "align-items": "center",
        "gap": ['6px', '8px']
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": "50px",
        "height": ['16px', '18px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-box', {
      attrs: {
        "display": "block",
        "width": "30px",
        "height": ['16px', '18px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1)], 1)], 1)], 1)], 1)], 1);
  }), 1) : !_vm.isLoading && _vm.items.length > 0 ? _c('c-box', _vm._l(Object.keys(_vm.itemsCategorized), function (key) {
    return _c('c-flex', {
      key: key,
      attrs: {
        "width": "100%",
        "flex-direction": "column",
        "gap": ['4px', '8px'],
        "px": ['8px', '0'],
        "py": "8px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['12px', '16px'],
        "font-weight": "400",
        "color": "#333"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDateV2(key)) + " ")]), _c('c-grid', {
      attrs: {
        "width": "100%",
        "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
        "gap": ['12px', '24px'],
        "background-color": "#FFF"
      }
    }, _vm._l(_vm.itemsCategorized[key], function (item, i) {
      return _c('c-flex', {
        key: i,
        attrs: {
          "width": "100%",
          "flex-direction": "column",
          "gap": ['4px', '8px']
        }
      }, [_c('c-flex', {
        attrs: {
          "width": "100%",
          "flex-direction": "row",
          "align-items": "center",
          "background-color": "#FFF",
          "border-radius": ['8px', '8px'],
          "gap": ['8px', '16px'],
          "padding": ['8px', '12px'],
          "box-shadow": ['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026'],
          "overflow": "hidden",
          "cursor": ['redeem', 'refund'].includes(item === null || item === void 0 ? void 0 : item.type) ? 'pointer' : 'default'
        },
        on: {
          "click": function click($event) {
            return _vm.onClickItem(item);
          }
        }
      }, [_c('c-flex', {
        attrs: {
          "flex-shrink": "0",
          "position": "relative",
          "align-items": "center",
          "justify-content": "center",
          "width": ['90px', '120px'],
          "height": "100%",
          "border-radius": "8px",
          "padding": "4px",
          "background-color": _vm.getItemBackgroundColor(item)
        }
      }, [_c('c-image', {
        attrs: {
          "src": _vm.getItemImage(item === null || item === void 0 ? void 0 : item.type),
          "width": "auto",
          "height": ['42px', '56px'],
          "margin-inline": "auto",
          "object-fit": "cover",
          "border-radius": ['4px', '8px']
        }
      })], 1), _c('c-flex', {
        attrs: {
          "flex-grow": "1",
          "flex-direction": "column",
          "justify-content": "space-between",
          "height": "100%",
          "gap": "0px"
        }
      }, [_c('c-flex', {
        attrs: {
          "flex-direction": "row",
          "align-items": "flex-start",
          "justify-content": "space-between",
          "gap": "8px"
        }
      }, [_c('c-text', {
        attrs: {
          "font-size": ['14px', '18px'],
          "font-weight": "500",
          "color": "#111"
        }
      }, [_vm._v(" " + _vm._s(_vm.getItemTitle(item)) + " ")]), (item === null || item === void 0 ? void 0 : item.type) == 'redeem' ? _c('c-text', {
        attrs: {
          "font-size": ['14px', '18px'],
          "font-weight": "500",
          "color": (item === null || item === void 0 ? void 0 : item.status) == 'failed' ? '#333' : 'danger.400',
          "flex-shrink": "0"
        }
      }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.status) == 'failed' ? item === null || item === void 0 ? void 0 : item.totalPrice : "-".concat(item === null || item === void 0 ? void 0 : item.totalPrice)) + " Poin ")]) : _c('c-text', {
        attrs: {
          "font-size": ['14px', '18px'],
          "font-weight": "500",
          "color": (item === null || item === void 0 ? void 0 : item.poin) <= 0 ? 'danger.400' : 'primary.400',
          "flex-shrink": "0"
        }
      }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.poin) > 0 ? "+".concat(item === null || item === void 0 ? void 0 : item.poin) : item === null || item === void 0 ? void 0 : item.poin) + " Poin ")])], 1), _c('c-flex', {
        attrs: {
          "flex-direction": "row",
          "align-items": "flex-end",
          "justify-content": "space-between"
        }
      }, [(item === null || item === void 0 ? void 0 : item.type) == 'redeem' ? _c('c-text', {
        attrs: {
          "color": (item === null || item === void 0 ? void 0 : item.status) == 'success' ? 'primary.400' : (item === null || item === void 0 ? void 0 : item.status) == 'failed' ? 'danger.400' : 'warning.400',
          "font-size": ['12px', '16px'],
          "font-weight": "500"
        }
      }, [_vm._v(" " + _vm._s(_vm.onCheckStatus(item === null || item === void 0 ? void 0 : item.status)) + " ")]) : (item === null || item === void 0 ? void 0 : item.type) == 'reset-poin' || (item === null || item === void 0 ? void 0 : item.type) == 'expired' ? _c('c-text', {
        attrs: {
          "color": "#111",
          "font-size": ['12px', '16px'],
          "font-weight": "500"
        }
      }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.note) + " ")]) : _c('c-text', {
        attrs: {
          "color": "primary.400",
          "font-size": ['12px', '16px'],
          "font-weight": "500"
        }
      }, [_vm._v(" Berhasil ")]), _c('c-text', {
        attrs: {
          "color": "#333",
          "font-size": ['12px', '16px']
        }
      }, [_vm._v(" " + _vm._s(_vm.formatDateV2(item === null || item === void 0 ? void 0 : item.createdAt, 4)) + " ")])], 1)], 1)], 1)], 1);
    }), 1)], 1);
  }), 1) : _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "py": "60px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty",
      "width": ['180px', '200px'],
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '18px'],
      "font-weight": "500"
    }
  }, [_vm._v(" Riwayat Poin tidak ditemukan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }