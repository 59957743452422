var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm.isLoading ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(3, 1fr)', 'repeat(4, 1fr)'],
      "gap": ['12px', '24px'],
      "px": ['8px', '0'],
      "py": "1rem",
      "background-color": "#FFF"
    }
  }, _vm._l(['sm', 'md'].includes(_vm.currentBreakpoint) ? 9 : 8, function (i) {
    return _c('c-flex', {
      key: i,
      attrs: {
        "width": "100%",
        "flex-direction": "column",
        "background-color": "#FFF",
        "border-radius": ['8px', '12px'],
        "gap": "8px",
        "box-shadow": ['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026'],
        "overflow": "hidden"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": "100%",
        "height": ['55px', '145px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade"
      }
    })], 1), _c('c-box', {
      attrs: {
        "padding-inline": "8px",
        "padding-bottom": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "block",
        "width": "70%",
        "height": ['18px', '20px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1)], 1)], 1);
  }), 1) : !_vm.isLoading && _vm.items.length > 0 ? _c('c-box', [_c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(3, 1fr)', 'repeat(4, 1fr)'],
      "gap": ['12px', '24px'],
      "px": ['8px', '0'],
      "py": "1rem",
      "background-color": "#FFF"
    }
  }, _vm._l(_vm.items, function (gift, i) {
    return _c('c-flex', {
      key: i,
      attrs: {
        "width": "100%",
        "height": "fit-content",
        "flex-direction": "column",
        "background-color": "#FFF",
        "border-radius": ['8px', '12px'],
        "gap": "8px",
        "box-shadow": ['0px 2px 8px 0px #00000026', '2px 2px 10px 0px #00000026'],
        "overflow": "hidden",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickGift(gift);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": gift === null || gift === void 0 ? void 0 : gift.image,
        "width": "100%",
        "height": ['55px', '145px'],
        "object-fit": "cover"
      }
    }), _c('c-text', {
      attrs: {
        "font-size": ['10px', '16px'],
        "font-weight": "500",
        "color": "#111",
        "padding-inline": "8px",
        "padding-bottom": "8px"
      }
    }, [_vm._v(" " + _vm._s(gift === null || gift === void 0 ? void 0 : gift.name) + " ")])], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "w": "100%",
      "margin-top": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "px": "0",
      "py": "4px",
      "w": "32px",
      "h": "32px",
      "background-color": "transparent",
      "is-disabled": _vm.page <= 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onPrevious.apply(null, arguments);
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (page_) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n            ':hover': {\n              bg: 'rgba(0, 140, 129, 0.3)',\n              color: 'primary.400',\n            },\n          }"
      }],
      key: page_,
      attrs: {
        "px": "0",
        "py": "4px",
        "w": "32px",
        "h": "32px",
        "mx": "4px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": page_ === _vm.page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: page_
          })
        }
      }
    }, [_vm._v(" " + _vm._s(page_) + " ")]);
  }), _c('c-button', {
    attrs: {
      "px": "0",
      "py": "4px",
      "w": "32px",
      "h": "32px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.page
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onNext.apply(null, arguments);
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1) : _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "py": "60px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty",
      "width": ['180px', '200px'],
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '18px'],
      "font-weight": "500"
    }
  }, [_vm._v(" Hadiah tidak ditemukan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }